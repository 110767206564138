import React from 'react'

import { Link} from 'gatsby'

//Helpers
import {getLocation, getName, getFakeAddress, getStatus, is_Sold, getDescription} from '../helpers/helper.developments'
import {getCover, getQuality, getPriceForMarker, getTextShort, checkPage} from '../helpers/helper.rendering'
import {getMinAndMaxPrice} from '../helpers/helper.unities'
import { graphql, useStaticQuery } from 'gatsby';
import { getEnvironment, getPrices, getSurface, makeLink } from '../helpers/helper.properties';
import { connect } from 'react-redux'
import { changeSelectedAction } from '../redux/propertiesDucks'
import { useLocation } from '@reach/router'

const developmentCard = (props) => {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
          }
    }`)
    const {development} = props
    const {dispatch} = props
    const inMap = props?.inMap
    const unities = props?.unities
    const isGallery = props?.gallery
    const isDevFilter = props?.devFilter

    const makeUrlUnities = (unities) => {
        let url = ''
        unities.forEach((unity, index) => {
            url += `${unity.id}`
            if(index !== unities.length - 1){
                url += `-`
            }
        })
        return url
    }
    const location = useLocation()
    const isDevsPage = location.pathname.includes("emprendimientos")
    const isHomePage = location.pathname.length === 1 && location.pathname.includes("/")
    const showLocation = () => {
        if(isGallery) {
            return false;
        } else if (isDevFilter) {
            return false;
        } else {
            return true;
        }
    }

    return inMap ? (
            <div class={"card prop-card " + (inMap ? 'map-card' : '')}>
            <div class={"card-image grid_map_image"}>
                <a target="_blank" >
                    <img src={getQuality(getCover(development.photos)) } className='notScale' alt={realEstate?.name} />
                </a>
                <div  class="badges">
                    <div  className={"badge"}>{"EMPRENDIMIENTO"}</div>
                </div>
                <div data-bs-dismiss="modal" onClick={() => dispatch(changeSelectedAction(0))} className="close-card"><i></i><i></i></div>
            </div>
            <div class="card-body">
                <div class="content-extra row d-flex justify-content-between align-items-start">
                    <div class="col-12 col-lg-6 mb-3 extra-data">
                        <span>{getFakeAddress(development)}</span>
                    </div>
                    <div class="col-12 col-lg-6 mb-2 text-lg-end location">
                        <span>
                            {getLocation(development)}
                        </span>
                    </div>
                    
                </div>
                <div class="content-extra">
                    <h3 class="card-title d-flex flex-column mb-3">
                        <a className='' target={"__blank"} href={"/emprendimientos/" + development.id + (props.unities ? '?unidades=' + makeUrlUnities(props.unities) : '')} >{getName(development)}</a>
                    </h3> 
                    <div className={"col-12 mb-3 " + (unities.length < 7 ? 'd-none' : '')}>
                        {"Desde USD " + Intl.NumberFormat("de-DE").format(getMinAndMaxPrice(unities).min)} <br/> {"Hasta USD " + Intl.NumberFormat("de-DE").format(getMinAndMaxPrice(unities).max)}
                    </div>
                    <div className="unity mb-3">
                            {unities.length < 7 ? 
                                unities.sort((a, b) => a.room_amount - b.room_amount).map((unity, index) => (
                                    <div className="item-unity mb-1 d-flex align-items-center">
                                        {getEnvironment(unity)} ambientes · {getSurface(unity) || '000.00 M2'} · {getPriceForMarker(getPrices(unity)[0])} <a className="ms-auto"  target={"__blank"} href={makeLink(unity)}>Ver + </a>
                                    </div>
                                ))
                                :''
                            }
                    </div>
                    {/* <h3 class={"card-title d-flex flex-column " + (unities.length < 7 ? 'd-none' : '')}> */}
                    <h3 class={"card-title d-flex flex-column " }>
                        <a className='btn' target={"_blank"} href={"/emprendimientos/" + development.id + (props.unities ? '?unidades=' + makeUrlUnities(props.unities) : '')} >Ver emprendimiento</a>
                    </h3>  
                </div>
            </div>
        </div>
        )
        :(
        <a href={"/emprendimientos/" + development.id + (props.unities ? '?unidades=' + makeUrlUnities(props.unities) : '')}  target={"_blank"}>
            <div class={"card dev-card mb-3 " + (props.inMap ? 'map-card' : '') + (isDevsPage ? 'dev-page-cards' : '')}>
                {/* {is_Sold(development) && 
                    <div className="development-end-tag">100% VENDIDO</div>
                } */}
                <div class="card-image">
                    <img class="card-img" src={development && getQuality(getCover(development.photos))} alt={realEstate?.name} />
                </div>
                { !isGallery && <div class="badge">{development && getStatus(development)}</div>}
                <div class="card-img-overlay d-flex align-items-center align-items-lg-start flex-lg-column text-white">
                    <div className="container-fluid">
                        <div className={`content d-flex flex-column ${ !isGallery ? 'mt-auto' : 'gallery-margin' }`}>
                            { showLocation() && <h5 class="d-inline-block d-none card-title direccion mb-0 mb-lg-3 mt-lg-2">{development && getFakeAddress(development)} - {development && getLocation(development,true)[0]}</h5> }
                            <a href={"/emprendimientos/" + development.id + (props.unities ? '?unidades=' + makeUrlUnities(props.unities) : '')} class="mb-3"  target={"_blank"}>
                                <h4 class="card-title mb-0">{development && getName(development)}</h4>   
                            </a>
                            {<h5 class="d-inline-block card-title direccion mb-0 mb-lg-3 mt-lg-2">{development && getFakeAddress(development)} - {development && getLocation(development,true)[0]}</h5> }
                            { !isDevFilter && getDescription(development).length > 0
                                ? <p class="card-location d-none d-lg-block">
                                    { isGallery 
                                        ? getTextShort(getDescription(development), 200)
                                        : getTextShort(getDescription(development), 220) }
                                </p> 
                                : '' }
                            { !isDevFilter && !isHomePage
                                ?   <a href={"/emprendimientos/" + development.id + (props.unities ? '?unidades=' + makeUrlUnities(props.unities) : '')} class="btn btn-red mt-5" target={"_blank"}>
                                        Conocé más<i className="icon-arrow-stick-light"></i>
                                    </a> 
                                : '' }
                        </div>
                    </div>
                    {/* {
                        props.inMap ?
                            <a target={"__blank"} href={"/emprendimientos/" + development.id + (props.unities ? '?unidades=' + makeUrlUnities(props.unities) : '')} class="btn btn-outline mt-auto"><span class="d-none d-lg-inline">DESCUBRILO</span><span class="icon-plus d-lg-none"></span></a>
                        :
                            <Link to={"/emprendimientos/" + development.id + (props.unities ? '?unidades=' + makeUrlUnities(props.unities) : '')} class="btn btn-outline mt-auto"><span class="d-none d-lg-inline">DESCUBRILO</span><span class="icon-plus d-lg-none"></span></Link>
                    } */}
                </div>
            </div> 
        </a>
    )
}

export default connect(state => ({
    settings: state.settings,
}),null)(developmentCard);