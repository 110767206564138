import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

//Components
import About from '../../components/Emprendimientos/about-dv'
import Featured from "../../components/Emprendimientos/featured"
import ContactWrapper from '../../components/ContactWrapper'
import BannerTop from "../../components/Modules/MediaPush/BannerTop"


const IndexPage = () => (
  <Layout>
    <Seo title="Emprendimientos" />
    <BannerTop page="emprendimientos"/>
    <Featured/>
    <About />
    <ContactWrapper/>
  </Layout>
)

export default IndexPage
