import React,{useEffect, useState, useRef} from 'react'
import Development from '../development-card'
import Loading from '../Loading'
import { connect } from 'react-redux'
import {getDevelopmentsFilterAction, updateFiltersDevelopment, cleanDevelopmentAction} from '../../redux/developmentsDucks'
import { getReverseOrder } from '../../helpers/helper.developments'
import { useLocation } from "@reach/router";

const Developments = ({developmentsFilters,stateCodes,loading,api_key,dispatch}) => {

    const location = useLocation()
    const isEmprendimientosPage = location.pathname.includes("emprendimientos")

        
    const statusSelect = useRef(null);
    const statusType = useRef(null);
    const statusLocation = useRef(null);
  
    const [dinamicTypes,setTypes] = useState([{id:0,name:"Tipo"}]); 
    const [dinamicLocations,setLocation] = useState([{id:0,full_location:"Ubicación"}]); 

    const [filter,setFilter] = useState(0)
    const [buttons,setButtos] = useState([
        {
            name:"VER TODOS",
            value:0
        },
        {
            name:"EN POZO",
            value:3
        },
        {
            name:"EN CONSTRUCCIÓN",
            value:4
        },
        {
            name:"TERMINADOS",
            value:6
        },

    ])

    useEffect(() => {
        if(developmentsFilters.data && dinamicTypes.length === 1){
          let newsTypes = [{id:'',name:"Tipo"}]
          for (const iterator of developmentsFilters.data.objects) {
            if(newsTypes.filter(element => element.id.toString() === iterator.type.id.toString()).length === 0){
              newsTypes.push(iterator.type)
            }
          }
          setTypes(newsTypes)
        }
        if(developmentsFilters.data && dinamicLocations.length === 1){
          let newLocations = [{id:'',full_location:"Ubicación"}]
          for (const iterator of developmentsFilters.data.objects) {
            if(newLocations.filter(element => element.id.toString() === iterator.location.id.toString()).length === 0){
              newLocations.push(iterator.location)
            }
          }
          setLocation(newLocations)
        }
    },[developmentsFilters.data])
  
    function updateFilters() {
        dispatch(cleanDevelopmentAction(1));
        dispatch(
          updateFiltersDevelopment(
            '',
            statusLocation.current.value,
            statusSelect.current.value,
          )
        );
        dispatch(getDevelopmentsFilterAction());
      }

    useEffect(() => {
        if(api_key){	
            dispatch(updateFiltersDevelopment(filter))
            dispatch(getDevelopmentsFilterAction()) // Get all developments
        }
    },[filter,api_key])

    function makeShortLocationFilter(text) {
        if(text.full_location === "Ubicación" )return "Ubicación";
        const spliter = text.full_location.split("|");
        return text.name; 
      }

    return  (
        <section id="featured-devs" className={`py-lg-5 ${isEmprendimientosPage ? "emprendimientos-dev" : ""}`}>
                    <div className="container-fluid my-5 pb-lg-3">
                        <div className="d-lg-flex justify-content-center">
                            <div className="content-select">
                                <select
                                name="state"
                                ref={statusSelect}
                                openMenuOnFocus={true}
                                onChange={() => updateFilters()}
                                id="state"
                                >
                                <option value="">Estado de la construcción</option>
                                {stateCodes.map((statusCode, index) =>
                                    statusCode.id >= 0 ? (
                                    <option key={statusCode.id} value={statusCode.id}>
                                        {statusCode.name}
                                    </option>
                                    ) : (
                                    ""
                                    )
                                )}
                                </select>
                                <i id="openState" className="icon-arrow-stick-light" onClick={() => statusSelect?.current?.focus() }></i>
                            </div>
                            <div className="content-select">
                                <select
                                name="city"
                                ref={statusLocation}
                                onChange={() => updateFilters()}
                                id=""
                                >
                                {dinamicLocations.map((location, index) =>(
                                    <option value={location.id} key={location.id}>{makeShortLocationFilter(location)}</option>
                                ))}
                                </select>
                                <i className="icon-arrow-stick-light"></i>
                            
                            </div>
                        </div>
                       
                    </div>
                    {
                    !loading && developmentsFilters.data ?
                        <div class="container-fluid">
                            {/* {console.log(statusSelect?.current?.value)} */}
                            {/* {console.log(stateCodes.find(element => element?.id?.toString() === statusSelect?.current?.value?.toString()))} */}
                            <div class="row no-gutters mt-5 mt-lg-1" >
                                {
                                    developmentsFilters.data.objects.length > 0 ?
                                        (developmentsFilters.data?.objects).map((development,index) => (
                                            <div class="col-lg-4 mb-3 mb-lg-4 pb-lg-2 px-lg-3">
                                                <Development key={index} development={development} devFilter={true}/>
                                            </div>                  
                                        ))
                                    :
                                    <div class="col-lg-12 d-flex justify-content-center text-center">
                                        <h5 className="text-center">
                                            No hay emprendimientos 
                                            {statusSelect?.current?.value ? " " + stateCodes.find(element => element?.id?.toString() === statusSelect?.current?.value?.toString())?.name?.toLowerCase() : ''} 
                                            {statusLocation?.current?.value ? " en la ubicación " + dinamicLocations.find(element => element?.id.toString() === statusLocation?.current?.value?.toString())?.name : ''} 
                                        </h5>
                                    </div>
                                }
                            </div>
                        </div>
                    :<Loading />
                    }
            
        </section> 
    )
}
export default connect(state => ({
    developmentsFilters: state.developments.developmentsFilters,
    stateCodes: state.developments.stateCodes,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko

}),null)(Developments);