import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import Card from '../development-card'
import OwlCarousel from 'react-owl-carousel3'
import { getFeatured } from '../../helpers/helper.developments'
import { useLocation } from '@reach/router'
import { cleanDevelopmentAction } from '../../redux/developmentsDucks'

const options = {
    loop:true,
    smartSpeed:1000,
    stagePadding:0,
    center:true,
    margin:0,
    dots: true,
    dotsEach: true,
    startPosition:0,
    items:3,
    responsive: {
        0: {
            autoWidth:false,
            items: 1,
        },
        400: {
            autoWidth:false,
            items: 1,
        },
        600: {
            autoWidth:false,
            items: 1,
        },
        991: {
            autoWidth:false,
            items: 1,
        },
        992: {
            items: 1,
        }
    },
}

const Featured = ({developments,dispatch}) => {

    const slider = useRef()
    const location = useLocation()
    useEffect(() => {
        dispatch(cleanDevelopmentAction())
    },[location])

    return developments?.data?.objects?.length > 0 
        && getFeatured(developments?.data?.objects).length > 0 ? (
        <section id="featured-devs">
            <div id="slider-devs-section" className="developments-gallery-wrapper featured-developments-gallery">
                <OwlCarousel ref={slider} {...options} className="overflow-hidden">
                    { getFeatured(developments?.data?.objects).map((development, index) => (
                        <Card key={index} development={development} gallery={true}/> ))}
                </OwlCarousel>
                {/* <div className="arrows-owl zindex-1">
                    <div className="arrow" onClick={() => slider.current.prev()}> <i></i> <i></i></div>
                    <div className="arrow reverse" onClick={() => slider.current.next()}> <i></i> <i></i> </div>
                </div> */}
                <div className={"arrows-owl-isq zindex-1"}>
                    <div className="arrow" onClick={() => slider.current.prev()}> <i></i> <i></i></div>
                </div>
                <div className={"arrows-owl-der zindex-1"}>
                    <div className="arrow" onClick={() => slider.current.next()}> <i></i> <i></i> </div>
                </div>
            </div>
        </section> 
    ) : ''
}

export default connect(state => ({
    developments: state.developments.developments,
}),null)(Featured);